/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, {useContext, useEffect, useRef, useState } from 'react'

import { StyleClass } from 'primereact/styleclass';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { Tree } from 'primereact/tree';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux'
import { motion } from "framer-motion"


import { carouselIndexEcoAccion } from '../../../Redux/patos/EcoDuck';
import Cargando from '../comps/Cargando';

import { obtenerNavbarAccionC } from '../../../Redux/patos/navbarDuck';

import { UserContext } from '../../../context/UserProvider';
//import { Link } from 'react-router-dom';

import './Stylo.css'

import imgPruebita from '../../../assets/img/banner_foot.png'

const InicioNew = () =>{

    const dispatch = useDispatch();
    const ecoCarousel = useSelector(store => store.ecoCarousel)
    const [page, setPage] = useState(0)

    const {userBer, userFire} = useContext(UserContext);

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    });

    //console.log(mousePosition)

    useEffect(() => {

        const mouseMove = e =>{
            //console.log(e)
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener('mousemove', mouseMove);

        return () =>{
            window.removeEventListener('mousemove', mouseMove);
        }

    }, []);


    const variants ={
        default:{
            x: mousePosition.x - 10,
            y: mousePosition.y - 10
        }
    }
    const [cursorX,setCursorX] = useState();
    const [cursorY,setCursorY] = useState();



    useEffect(() => {
        const generarData = () =>{   
            dispatch(carouselIndexEcoAccion())
            
        }
        generarData()
    },[dispatch])


    useEffect(() => {
        const generarData = () =>{

            dispatch(obtenerNavbarAccionC())
        }
        generarData()
    },[dispatch])


    const navbarC = useSelector(store => store.navbarListaC)


   // console.log(navbarC )

    return(
        <>
            {/*<div className="surface-section">
                <div className="surface-section h-45rem bg-no-repeat bg-cover bg-center flex align-items-center" style={{background: ` url(${LogoPrueba})`}} >
                    {/* <div className="px-4 mx-4 lg:px-6 lg:mx-6"> 
                        <span className="block text-3xl text-white mb-4">New Trend</span>
                        <span className="block text-5xl font-medium text-white mb-4">Special Collection</span>
                        <a className="py-2 w-13rem text-center block mb-4 text-xl text-white font-medium border-2 cursor-pointer surface-border-0 border-round bg-white-alpha-30">Explore Collection</a>
                    </div>
                </div>
            </div>*/}
            {
                ecoCarousel.loadingCarousel ? (
                
                <div className='flex justify-content-center align-content-center'>
                    <Cargando />
                </div>
                )
                :
                (
                ecoCarousel.arrayCarousel.length > 0 ? (
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        speed={2000}
                        autoplay
                        navigation
                        pagination={{ clickable: true }}
                        /*onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}*/
                        className="z-0 w-screen hdef mb-4  " //hdef
                    >
                        
                        
                        {
                        ecoCarousel.loadingCarousel ? (
                                ''
                        )
                        :
                        (
                            ecoCarousel.arrayCarousel.map((car, index) =>(
                                        <SwiperSlide key={index} className=' overflow-hidden'>
                                            {/*<a  target="_blank" rel="noreferrer">
                                                <Image onClick={()=>console.log('click')} imageClassName='hidden md:inline-flex lg:inline-flex h-full w-full absolute stiloTrue bg-left' src={car.imagen} alt={car.link} /> 
                                                <Image imageClassName='inline-flex md:hidden lg:hidden h-full w-full absolute stiloTrue bg-left' src={car.imgresponsive} alt={car.link} /> 
                                            </a>*/}
                                            
                                            <div className="hidden surface-section h-full w-screen stiloTrue bg-center md:flex lg:flex align-items-end " style={{background: `url(${car.imagen})`}}>
                                                <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                    <a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a>
                                                </div>
                                            </div>
        
                                            <div className="flex surface-section h-full w-screen stiloTrue overflow-hidden bg-no-repeat bg-cover bg-top md:hidden lg:hidden align-items-end " style={{background: `url(${car.imgresponsive})`}}>
                                                <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                    <a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    
        
                            ))
                        )
                        }
        
                    </Swiper>
                ):(
                    <div className='h-30rem flex justify-content-center align-self-center align-content-center'>
                        <Cargando />
                    </div>
                )
                
                )
            }


            <Divider layout="horizontal" className="surface-border mt-8 max-w-screen"></Divider>
            
            
            <div className='surface-section  py-8 z-2'>

                
                <div className='grid '>
                    {
                        navbarC.loadingC ? (
                            
                                <div className='col-12'> <Cargando /> </div>
                            
                            
                        )
                        :
                        (
                            navbarC.arrayC.map((navcate, index) => (
                                
                                    index === 0 || index % 2 === 0 ? 
                                        (
                                        <div key={index} className='col-12 md:col-6 mb-8'>
                                            <div className='relative'>
                                                <div className=' box '>
                                                    <div className='movede grid flex flex-wrap align-content-center align-items-center justify-content-end z-1'>
                                                        <div className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                            <h1 className='mb-0 font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0 font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl md:text-right px-6 md:pl-4'>{navcate.descripcion}</h2>
                                                        </div>
                                                        <div id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                                            <img src={`${navcate.image}`} alt="stad1" className="w-20rem h-30rem border-round "/>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                    <div id='activador'  className='fondoCate absolute h-30rem p-0 m-0 w-10 z-0'>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        )
                                    :
                                        (
                                        <div key={index} className='col-12 md:col-6 '>
                                            <div className='relative'>
                                                <div className='box-izq '>
                                                    <div className='moveiz grid flex align-content-center align-items-center justify-content-start  z-1 '>

                                                        <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                            <img  src={`${navcate.image}`} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round "/>
                                                        </div>
                                                        <div  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                            <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl  md:text-left px-6 md:pr-4'>{navcate.descripcion}</h2>
                                                        </div>
                                                        

                                                        <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                                            <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl px-6 md:pr-2'>{navcate.descripcion}</h2>
                                                        </div>
                                                        <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                            <img  src={`${navcate.image}`} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round "/>
                                                        </div>

                                                    </div>
                                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                    
                                                    <div id='activador'  className='fondoCate-izq absolute h-30rem p-0 m-0 w-10 z-0 right-0'>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        )
   
                            ))
                        )
                    }

                    {/*<div className='col-12 md:col-6'>
                        <div className='relative'>
                            <div className='box'>
                                <div className='movede grid flex align-content-center align-items-center z-1'>
                                    <div className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                        <h1 className='mb-0 font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0 font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl md:text-right px-6 md:pl-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    <div id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>
                                    
                                </div>
                                {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                <div id='activador'  className='fondoCate absolute h-30rem p-0 m-0 w-full z-0'>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className='col-12 md:col-6'>
                        <div className='relative'>
                            <div className='box-izq'>
                                <div className='moveiz grid flex align-content-center align-items-center z-1 '>

                                    <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>
                                    <div  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                        <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl  md:text-left px-6 md:pr-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    

                                    <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                        <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl px-6 md:pr-2'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>

                                </div>
                                {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                <div id='activador'  className='fondoCate-izq absolute h-30rem p-0 m-0 w-full z-0'>
                                </div>
                            </div>
                            
                        </div>
                    </div>*/}

                </div>
            </div>

            <div className='relative p-8 overflow-hidden bg-no-repeat bg-cover bg-top h-45rem md:h-screen align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imgPruebita})`}}>
                    
                <div className='text-center my-6 relative'>
                    <div className="text-6xl text-white font-bold mb-1">BERSATI</div>
                    <div className="text-6xl text-white font-bold mb-4">Brindando calidad, elegancia y comodidad.</div>

                    <p className="text-lg mt-4 mb-4 line-height-3 text-white">Una tienda diseñada para reflejar tu estilo.</p>
                </div>

            </div>

        </>
    )
}

export default InicioNew