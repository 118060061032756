/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
//import { ProductService } from '../service/ProductService';
import './EditPrecioProducto.css';


import axios from "axios";

const EstadoProducto = () => {

  const [products4, setProducts4] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const toast = useRef(null);

  const paymentOptions = [
    {name: 'Activo', estado: 1},
    {name: 'Oculto', estado: 0},
  ];

  useEffect(() => {
    setLoading(true)
    const generarData = async () =>{
        
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

        setProducts4(res.data)
        setLoading(false)
    }
    generarData()
    
  
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const generarData = async () =>{
    setLoading(true)
    const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

    setProducts4(res.data)
    setLoading(false)
  }


  const EditEstado = async (val, rowData) =>{

    setLoadingBtn(true)

    let id = rowData.id;
    let data = {
      estado : val
    }
    const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/save/estadoproducto/${id}`, data);

    if (resSave.status === 200) {      
      toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });
      setLoadingBtn(false)
    }else {
        toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
        setLoadingBtn(false)
    }
    

    generarData()
    setLoadingBtn(false)
  }

  const estadoBodyTemplate = (rowData) => {
    return <SelectButton className='z-0' disabled={loadingBtn} value={rowData.estado} optionLabel="name" optionValue="estado" unselectable onChange={(e) => EditEstado(e.value, rowData)} options={paymentOptions}  />;
  }

  return (
    <>
        <div className="datatable-editing-demo w-full ">
            <Toast ref={toast} />
            <div className="surface-section py-5 px-2 md:px-3 lg:px-5" >
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">EDICIÓN DE LOS ESTADOS DE LOS PRODUCTOS</span>
                </div>
            </div>
            <div className="card w-full p-fluid px-2 md:px-3 lg:px-5" >
                <DataTable value={products4} paginator rows={20} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos" size="small"  showGridlines 
                editMode="cell"  className="editable-cells-table overflow-scroll" responsiveLayout="scroll" scrollable >
                    {/*
                        columns.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} filter sortable style={{ width: '25%' }} body={field === 'price' && priceBodyTemplate}
                                editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                        })
                    */}
                    <Column field='codigo' header='Código' filter sortable style={{ minWidth: '13rem' }}  />
                    <Column field='codigo_selkis' header='Cod. Selkis' filter sortable style={{ minWidth: '13rem' }}  />
                    <Column field='nombre' header='Nombre' filter sortable style={{ minWidth: '16rem' }}  />
                    <Column field='estado' header='Estado' filter sortable style={{ minWidth: '16rem' }} body={estadoBodyTemplate} />
                </DataTable>
            </div>
        </div>  
    </>
  )
}

export default EstadoProducto