/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { BrowserRouter } from "react-router-dom";


/*        Estilos de PrimreReact PrimeFlex             */
import "primereact/resources/themes/tailwind-light/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css";

/*  -------------------------------------------        */

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';



import './index.css';
import UserProvider from './context/UserProvider';
import PaymentProvider from './context/PaymentProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <PaymentProvider>
          <App />
        </PaymentProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
  
);
