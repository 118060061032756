/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ScrollTop } from 'primereact/scrolltop';
//import { PrimeIcons } from 'primereact/api';

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import NavbarVisitors from './comps/NavbarVisitors'
import FooterVisitors from './comps/FooterVisitors'

import { useSelector, useDispatch } from 'react-redux';

import { verificarToken } from '../../Redux/patos/userDuck';  
import NavbarNew from './comps/NavbarNew';

import './Principal.css'
const PrincipalVisitors = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  //console.log(process.env.REACT_APP_UNSPLASH_URL)

  const [consulta, setConsulta] = useState('false');
  const [token, setToken] = useState('false');

  useEffect(() =>{

    const generarData = (token) =>{
      if (consulta) {
        dispatch(verificarToken(token))
      }
    }

    generarData(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[consulta])

  const userToken = useSelector((store) => store.userToken);
  //console.log(userToken)
  useEffect(() => {
    try {

      if (atob(localStorage.getItem('_uid')) === 'ée') {
        setConsulta(false)
      }
      if (atob(localStorage.getItem('_uid'))) {
        setToken(atob(localStorage.getItem('_uid')))
        setConsulta(true)
      }
  
      
    } catch (error) {
      setToken(null)
      setConsulta(false)
    }
  
  }, [])
  
  return (
    <>
      <ScrollTop />
     
      < NavbarNew />
        <Outlet></Outlet>

        <ScrollTop target="parent" threshold={100} behavior className="custom-scrolltop" icon="pi pi-arrow-up" />
      {location.pathname === '/' || location.pathname ==='/quienesomos' || location.pathname ==='/contacto' || location.pathname ==='/marcasxbersati' || location.pathname === '/register' || location.pathname === '/login' || location.pathname === '/paginacarrito' ?(
        < FooterVisitors />
      ):(
        ''
      )}
    </>
  )
}

export default PrincipalVisitors