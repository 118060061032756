/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Rating } from 'primereact/rating';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';

import axios from "axios";

const ListaProducto = () => {

  const [products, setProducts] = useState(null);
  const [product, setProduct] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [boton, setBoton] = useState(false)

  const [loading, setLoading] = useState(false);

  const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [filters1, setFilters1] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nombreCategoria': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombreFamilia': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombreLinea': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombreGrupoProductos': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

  const toast = useRef(null);
  const dt = useRef(null);
  const [globalFilterValue2, setGlobalFilterValue2] = useState('');

  const [text1, setText1] = useState('')


  const [categorias, setCategorias] = useState(null);
  const [familias, setFamilias] = useState(null);
  const [lineas, setLineas] = useState(null);
  const [gp, setGP] = useState(null);


  const [familias1, setFamilias1] = useState([]);
  const [lineas1, setLineas1] = useState(null);
  const [gp1, setGP1] = useState(null);
  const [paq, setPaq] = useState([]);

    const [estadoFami, setEstadoFami] = useState(true);
    const [estadoLine, setEstadoLine] = useState(true);
    const [estadoGP, setEstadoGP] = useState(true);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);


  const formik = useFormik({
    initialValues: {
      id: '',
      nombre: '',
      codigo: '',
      descripcionCorta: '',
      rating: null,
      tipo_paquete: '',
      descripcionLarga: '',
      categoria: '',
      familia: '',
      linea: '',
      grupoProductos: '',
  },onSubmit: (data) => {
    setBoton(true)
    formik.values.descripcionLarga = text1;

    //console.log(data)
    procederSave(data);
    formik.resetForm();

    setBoton(false)
  }
  })

  const procederSave = async (data) => {
    setLoading(true)


    const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/save/productoupdate/${data.id}`, data);

    //const result = await resSave.json();
    hideDialog()
    //console.log(resSave)

    if (resSave.status === 200) {
        toast.current.show({ severity: 'success', summary: 'Acción Realizada con Exito', detail: 'El producto fue editado correctamente.', life: 4000 });
    }else{
        toast.current.show({ severity: 'error', summary: 'Acción NO Realizada', detail: 'El producto NO fue editado.', life: 4000 });
    }

    const generarData = async () =>{
            
      const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

      

      setProducts(res.data)
    }

    generarData()
    setLoading(false)
}

  useEffect(()=>{
    const DataLoadPaquete = async () =>{

      const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/tipopaquete')
      

      setPaq(res.data)

    } 

    DataLoadPaquete()
  },[])

  useEffect(()=>{
       
    const respuesta = () =>{
        if (formik.values.categoria) {
            setEstadoFami(false)
            let varcito = []
            for (let index = 0; index < familias.length; index++) {
                if (familias[index].categoria_id === formik.values.categoria) {
                    varcito.push(familias[index])
                }else{
                    //familias1.splice(index, 1)
                }
            }
            setFamilias1(varcito)

        }else{
            setEstadoFami(true)
        }
    }

    respuesta()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formik.values.categoria])

useEffect(()=>{
       
  const respuesta = () =>{
      if (formik.values.familia) {
          setEstadoLine(false)
          let varcito1 = []
          for (let index = 0; index < lineas.length; index++) {
              if (lineas[index].familias_id === formik.values.familia) {
                  varcito1.push(lineas[index])
              }else{
                  //familias1.splice(index, 1)
              }
          }
          setLineas1(varcito1)

      }else{
          setEstadoLine(true)
      }
  }

  respuesta()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formik.values.familia])

useEffect(()=>{
 
  const respuesta = () =>{
      if (formik.values.linea) {
          setEstadoGP(false)
          let varcito2 = []
          for (let index = 0; index < gp.length; index++) {
              if (gp[index].lineas_id === formik.values.linea) {
                  varcito2.push(gp[index])
              }else{
                  //familias1.splice(index, 1)
              }
          }
          setGP1(varcito2)

      }else{
          setEstadoGP(true)
      }
  }

  respuesta()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formik.values.linea])

  const [filters2, setFilters2] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'nombreCategoria': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'nombreGrupoProductos': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  });

  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2['global'].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  }


  useEffect(() => {
    setLoading(true)
        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')
            console.log(res)
            setProducts(res.data)
        }
        generarData()

      setLoading(false)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    setLoading(true)

    const generarData1 = async () =>{
        const resCate = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')
        const resFami = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')
        const resLine = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/lineas')
        const resGP = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/gp')
        
        setCategorias(resCate.data)
        setFamilias(resFami.data)
        setLineas(resLine.data)
        setGP(resGP.data)

    }

    generarData1()

    setLoading(false)

},[])

  const exportCSV = () => {
    dt.current.exportCSV();
    
  }

  const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
          <Link to={'/protected/admin/nuevoproducto'} className='no-underline'>
            <Button label="Nuevo Producto" icon="pi pi-plus" className="p-button-success mr-2" />
          </Link>
        </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
          </React.Fragment>
      )
  }


  const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Lista de Producto</h5>
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Buscar..." />
        </span>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <div className='flex'>
                <Button icon="pi pi-pencil" placeholder="Left" tooltip="Editar Texto" tooltipOptions={{position: 'left'}} onClick={() => editProduct(rowData)} className="p-button-rounded p-button-warning "  />
                <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar Producto" tooltipOptions={{position: 'left'}} onClick={() => confirmDeleteProduct(rowData)} className="p-button-rounded p-button-danger ml-1"  />
            </div>
        </React.Fragment>
    );
  }

  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  }

  const tipoPaqueteTemplate = (rowData) => {
    return ''
  }

  const imageBodyTemplate = (rowData) => {
    return <img src={`${rowData.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imagenPrincipal} className=" w-8rem" />
  }





  
  const editProduct = (product) => {
    setProduct({...product});
    
    formik.values.id = product.id;
    formik.values.nombre = product.nombre;
    formik.values.codigo = product.codigo;
    if (product.descripcionCorta === null) {
      formik.values.descripcionCorta = '';
    }else{
      formik.values.descripcionCorta = product.descripcionCorta;
    }
    
    formik.values.descripcionLarga = product.descripcionLarga;
    setText1(product.descripcionLarga)
    formik.values.rating = product.rating;
    formik.values.categoria = product.categoria;
    if (product.tipo_paquete === null) {
      formik.values.tipo_paquete = '';
    }else{
      formik.values.tipo_paquete = product.tipo_paquete;
    }
    formik.values.familia = product.familia;
    formik.values.linea = product.linea;
    formik.values.grupoProductos = product.grupoProductos;

    setProductDialog(true);
  }

  const deleteProduct = async () => {
    setLoading(true)

    if (product.id) {
      //console.log(product)
      const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/save/productodelete/${product.id}`)

      //console.log(resSave)
      if (resSave.status === 200) {      
          toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Producto eliminado con éxito.', life: 3000 });
          setProduct(null);
          setDeleteProductDialog(false);

      }else if (resSave.status === 400) {
          setDeleteProductDialog(false);
          setProduct(null);
          toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Producto NO eliminado.', life: 3000 });
      }
    }


    const generarData = async () =>{
            
      const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

      setProducts(res.data)
    }

    generarData()

    setLoading(false)
  }

  const hideDialog = () => {
    formik.resetForm()
    setProduct(null)
    setProductDialog(false);
  }

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
    
  }
  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
    setProduct('')
  }
  const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
    </React.Fragment>
  );

  


  return (
    <>
      <Toast ref={toast} />
      <div className='datatable-crud-demo p-2  w-full  overflow-scroll'>
            <div className="surface-section py-5 px-2 md:px-3 lg:px-5" >
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">EDICIÓN DE LOS ESTADOS DE LOS PRODUCTOS</span>
                </div>
            </div>
        <div className="card text-sm px-2 md:px-3 lg:px-5">
          <Toolbar className="mb-1 text-sm p-2" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

          <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} filters={filters2} filterDisplay="row"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos" size="small"  showGridlines  
              header={header} responsiveLayout="stack" scrollable className='text-sm' >

              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
              <Column field="id" header="ID" sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="nombre" header="Nombre Producto" sortable style={{ minWidth: '16rem' }}></Column>
              <Column field="imagenPrincipal" header="Imagen" body={imageBodyTemplate} sortable style={{ minWidth: '10rem' }}></Column>
              <Column field="codigo" header="Código" sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="precio" header="Precio" sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="estado" header="Estado" sortable style={{ minWidth: '6rem' }}></Column>
              <Column field="descripcionCorta" header="Descripción Corta" sortable style={{ minWidth: '16rem' }}></Column>
              <Column field="descripcionLarga" header="Descripción Larga" sortable style={{ minWidth: '16rem' }}></Column>
              <Column field="rating" header="Rating" body={ratingBodyTemplate} sortable style={{ minWidth: '10rem' }}></Column>
              <Column field="tipoPaquete" header="Tipo Paquete"  sortable style={{ minWidth: '10rem' }}></Column>
              <Column field='nombreCategoria' filter  showFilterMenu={false} filterPlaceholder="Buscar..." header="Categoría" sortable style={{ minWidth: '15rem' }}></Column>
              <Column field="nombreFamilia" filter  showFilterMenu={false} filterPlaceholder="Buscar..." header="Familia" sortable style={{ minWidth: '15rem' }}></Column>
              <Column field="nombreLinea" filter  showFilterMenu={false} filterPlaceholder="Buscar..." header="Linea" sortable style={{ minWidth: '15rem' }}></Column>
              <Column field="nombreGrupoProductos" filter  showFilterMenu={false} filterPlaceholder="Buscar..." header="Grupo Producto" sortable style={{ minWidth: '15rem' }}></Column>

              <Column header='Acciones' body={actionBodyTemplate} exportable={false} style={{ minWidth: '13rem' }}></Column>
          </DataTable>


          <Dialog visible={productDialog} style={{ width: '450px' }} header="Editar Producto" modal className="p-fluid"  onHide={hideDialog}>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="field">
                  <label htmlFor="nombre">Nombre Producto</label>
                  <InputText id="nombre" value={formik.values.nombre} onChange={formik.handleChange}  required  />               
              </div>
              <div className="field">
                  <label htmlFor="codigo">Código</label>
                  <InputText id="codigo" value={formik.values.codigo} onChange={formik.handleChange}  required  />               
              </div>
              <div className="field">
                  <label htmlFor="rating">Rating</label>
                  <InputText id="rating" value={formik.values.rating} onChange={formik.handleChange}  required  />               
              </div>
              <div className="field">
                  <label htmlFor="tipo_paquete">Tipo Paquete</label>
                  
                  <Dropdown inputId="tipo_paquete" name="tipo_paquete" options={paq} value={formik.values.tipo_paquete} onChange={formik.handleChange} filter optionValue="id"  optionLabel="tipoPaquete"  />           
              </div>
              <div className='field'>
                  <label htmlFor="descripcionCorta">Descripción Corta</label>
                  <InputTextarea autoResize rows={3} cols={30}  id="descripcionCorta" name="descripcionCorta" value={formik.values.descripcionCorta} onChange={formik.handleChange} />
              </div>
              <div className='field'>
                  <label htmlFor="descripcionLarga">Descripción Larga</label>
                  <Editor style={{ height: '150px' }} id="descripcionLarga" name="descripcionLarga" value={text1} onTextChange={(e) => setText1(e.htmlValue)} className='mt-2' />
              </div>
              <div className='field'>
                  <label htmlFor="categoria">Categoria</label>
                  <Dropdown inputId="categoria" name="categoria" options={categorias} value={formik.values.categoria} onChange={formik.handleChange} filter optionValue="id"  optionLabel="nombreCategoria"  />
              </div>
              <div className='field'>
                  <label htmlFor="familia">Familia</label>
                  <Dropdown inputId="familia" name="familia" options={familias1} value={formik.values.familia} onChange={formik.handleChange} filter optionValue="id"  optionLabel="nombreFamilia"  />
              </div>
              <div className='field'>
                  <label htmlFor="linea">Linea</label>
                  <Dropdown inputId="linea" name="linea" options={lineas1} value={formik.values.linea} onChange={formik.handleChange} filter optionValue="id"  optionLabel="nombreLinea"  />
              </div>
              <div className='field'>
                  <label htmlFor="grupoProductos">Grupo de Productos</label>
                  <Dropdown inputId="grupoProductos" name="grupoProductos" options={gp1} value={formik.values.grupoProductos} onChange={formik.handleChange} filter optionValue="id"  optionLabel="nombreGrupoProductos"  />
              </div>
              <div className='flex'>
                {
                    boton ? (
                      <Button label="Cancelar" type="button" loading={loading} icon="pi pi-times" disabled className="p-button-rounded p-button-danger" onClick={hideDialog} />
                    )
                    :
                    (
                      <Button label="Cancelar" type="button" loading={loading} icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={hideDialog} />
                    )
                }
                {
                    boton ? (  
                        <Button label="Guardar Datos" type="button" loading={loading} icon="pi pi-check" disabled  className="p-button-rounded p-button-success" />
                    )
                    :
                    (
                        <Button label="Guardar Datos" type="submit" loading={loading} icon="pi pi-check" className="p-button-rounded p-button-success" />                           
                    )
                }
              </div>
            </form> 
          </Dialog>


          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {product && <span>Estas seguro de eliminar la familia <b>{product.nombre}</b>?</span>}
              </div>
          </Dialog>
        </div>
      </div>
    </>
  )
}

export default ListaProducto