/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react'

import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';


import bersatiLogo3 from '../../../assets/img/Bersati3.png'
import { Link } from 'react-router-dom';

const FooterVisitors = () => {
  return (
    <>
      <Divider layout="horizontal" className="surface-border mt-0 max-w-screen"></Divider>

      <div className="surface-section px-4 pb-4 md:px-6 lg:px-8">    
            <div className="grid grid-nogutter text-center lg:text-left">
                <div className="col-12 sm:col-6 md:col-4 lg:col-3 mt-4 flex flex-column align-items-center lg:align-items-start">
                    <img src={bersatiLogo3} className="w-9rem mx-auto lg:mx-0" alt="Peak logo" />
                    <div className="flex align-items-center w-full mt-5 justify-content-center lg:justify-content-start">
                        <a className="cursor-pointer mr-3">
                            <i className="pi pi-facebook text-900 border-1 border-round p-1 text-sm  text-0"></i>
                        </a>
                        <a className="cursor-pointer mr-3">
                            <i className="pi pi-twitter text-900 border-1 border-round p-1 text-sm  text-0"></i>
                        </a>
                        <a className="cursor-pointer mr-3">
                            <i className="pi pi-instagram text-900 border-1 border-round p-1 text-sm  text-0"></i>
                        </a>
                        <a className="cursor-pointer">
                            <i className="pi pi-whatsapp text-900 border-1 border-round p-1 text-sm  text-0"></i>
                        </a>
                    </div>

                    <a className="text-600 block mt-4 cursor-pointer hover:text-900 transition-duration-150 select-none w-8rem">© Bersati 2024</a>
                </div>
                <div className="col-12 sm:col-6 md:col-4 lg:col-3 mt-4 flex flex-column">
                    <span className="text-900 text-xl font-medium block">Servicio al Cliente</span>
                    <ul className="list-none p-0">

                        <li><a className="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Privacidad</a></li>
                        <li><Link to={'/quienesomos'}  className="no-underline text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Quienes Somos</Link></li>
                        
                    </ul>
                </div>
                <div className="col-12 sm:col-6 md:col-4 lg:col-3 mt-4 flex flex-column">
                    <span className="text-900 text-xl font-medium block">Store BERSATI</span>
                    <ul className="list-none p-0">

                        <li><a className="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Catálogo</a></li>
                        <li><Link to={'/marcasxbersati'}  className="no-underline text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Marcas</Link></li>
                        <li><a className="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Contacto</a></li>

                    </ul>
                </div>
                <div className="col-12 sm:col-6 md:col-4 lg:col-3 mt-4 flex flex-column">
                    <span className="text-900 text-xl font-medium block">Administración</span>
                    <ul className="list-none p-0">
                        <li><Link to={'/admin/login'} className="no-underline text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block">Administración</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default FooterVisitors