/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import React, {useContext, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import imgCB from '../../../assets/img/kartinka_4.jpg'

import './Stylo.css';

const ContactoNew = () =>{

    
    
    
    
    return(

        <>
            <div className='flex relative p-6 overflow-hidden bg-no-repeat bg-cover bg-bottom h-45rem md:h-screen align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imgCB})`}}>
                    
                    <div className='flex justify-content-end align-content-end text-center w-full bottom-0 '>

                        <div className="flex align-items-end  w-full text-6xl text-white font-light mb-4">Estamos aquí para ayudarte a transformar tus espacios. Contáctanos para cotizar o resolver cualquier duda, y juntos haremos realidad el hogar de tus sueños</div>
    
                    </div>
                    
            </div>
            <div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
                <div className='grid'>
                    <div className="col-12 md:col-6">
                        <div className="p-fluid pr-0 md:pr-6">
                            <div className="field">
                                <label  className="font-medium">Nombre Completo</label>
                                <InputText id="name"  className="py-3 px-2 text-lg"/>
                            </div>
                            <div className="field">
                                <label className="font-medium">Email</label>
                                <InputText id="email"  className="py-3 px-2 text-lg"/> 
                            </div>
                            <div className="field">
                                <label  className="font-medium">Celular</label>
                                <InputText id="company"  className="py-3 px-2 text-lg"/> 
                            </div>
                            <div className="field">
                                <label  className="font-medium">Mensaje</label>
                                <InputTextarea rows={5} cols={30}  className="py-3 px-2 text-lg"/> 
                            </div>
                            <Button  label="Enviar Mensaje" icon="pi pi-send" className="w-auto surface-900 "/>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default ContactoNew